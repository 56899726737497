import { Logger, sharedRef, useVSFContext } from '@vue-storefront/core';
import { computed, Ref } from '@nuxtjs/composition-api';

import type { MappedStore, MappedStores } from '@vue-storefront/novulo-api';

export const useStores = (): any => {
  const context = useVSFContext();

  const stores: Ref<MappedStores> = sharedRef({}, 'useStores-stores');
  const store: Ref<MappedStore> = sharedRef({}, 'useStores-store');

  const getStores = async () => {
    try {
      stores.value = await context.$novulo.api.getStores();
    } catch (e) {
      Logger.error('useStores/getStores', e);
    }
  };

  const getStore = async (store_id: number) => {
    try {
      store.value = (await context.$novulo.api.getStore({
        store_id
      })) as MappedStore;
    } catch (e) {
      Logger.error('useStores/getStore', e);
    }
  };

  const getStoresStatuses = async () => {
    try {
      const statuses = await context.$novulo.api.getStoresStatuses();
      stores.value = {
        ...stores.value,
        countryStoreList: stores.value.countryStoreList.map((country) => ({
          ...country,
          storeList: country.storeList.map((store) => {
            const storeStatus = statuses.find(
              (status) => status.storeId === store.id
            );
            if (!storeStatus) return store;
            return {
              ...store,
              current_status: storeStatus.currentStatus,
              is_currently_open: storeStatus.isCurrentlyOpen
            };
          })
        }))
      };
    } catch (e) {
      Logger.error('useStores/getStoreStatus', e);
    }
  };

  const getStoreStatus = async (store_id: number) => {
    try {
      const status = await context.$novulo.api.getStoreStatus({ store_id });
      if (!status.currentStatus) throw new Error('no status returned from api');
      store.value = {
        ...store.value,
        current_status: status.currentStatus,
        is_currently_open: Boolean(status.isCurrentlyOpen),
        openinghours: status.openingHours
      };
    } catch (e) {
      Logger.error('useStores/getStoreStatus', e);
    }
  };

  return {
    getStores,
    getStoresStatuses,
    getStore,
    getStoreStatus,
    stores: computed(() => stores.value),
    store: computed(() => store.value)
  };
};
